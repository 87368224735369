import React, { useState, useEffect, useRef } from 'react';
import MagicEdenIcon from '../icons/Magic Eden.png';
import DiscordIcon from '../icons/Discord.png';
import TwitterIcon from '../icons/Twitter.png';
import MediumIcon from '../icons/Medium.png';
import './Footer.css'; // Ensure the correct import path

const Footer = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const footerRef = useRef();

  const toggleFooter = () => {
    setIsExpanded(!isExpanded);
  };

  const handleClickOutside = (event) => {
    if (footerRef.current && !footerRef.current.contains(event.target)) {
      setIsExpanded(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <footer ref={footerRef} className={`custom-footer ${isExpanded ? 'expanded' : 'collapsed'}`}>
      <div className="footer-toggle" onClick={toggleFooter}>
        {isExpanded ? '▼' : '▲'}
      </div>
      <div className={`custom-links-container ${isExpanded ? 'expanded' : 'collapsed'}`}>
        <p>
          <a 
            href="https://magiceden.us/collections/sei/crafty-canine-1" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="custom-btn-link"
          >
            <img src={MagicEdenIcon} alt="Magic Eden" className="custom-social-icon" />
            <span>Buy Now on Magic Eden</span>
          </a>
        </p>
        <p>
          <a 
            href="https://discord.com/invite/NRmRcmJV6w" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="custom-btn-link"
          >
            <img src={DiscordIcon} alt="Discord" className="custom-social-icon" />
            <span>Join our Discord</span>
          </a>
        </p>
        <p>
          <a 
            href="https://x.com/crafty_canines" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="custom-btn-link"
          >
            <img src={TwitterIcon} alt="Twitter" className="custom-social-icon" />
            <span>Follow us on Twitter/X</span>
          </a>
        </p>
        <p>
          <a 
            href="https://medium.com/@craftycanines" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="custom-btn-link"
          >
            <img src={MediumIcon} alt="Medium" className="custom-social-icon" />
            <span>Follow us on Medium</span>
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
