import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './components/Home';
import DeFi from './components/DeFi';
import Exchanges from './components/Exchanges';
import NFTs from './components/NFTs';
import Wallets from './components/Wallets';
import Communities from './components/Communities';
import NewToSEI from './components/SEIGuides';
import Spaces from './components/Spaces';
import Meme from './components/Meme';
import MediaKits from './components/MediaKits';
import DevStuff from './components/DevStuff';
import Bridges from './components/Bridges';
import Explorers from './components/Explorers';
import OtherTools from './components/OtherTools';
import SeiGames from './components/SeiGames'; // Import the SeiGames component
import { Analytics } from "@vercel/analytics/react"

const pageVariants = {
  initial: {
    opacity: 0,
    scale: 0.9,
    y: 50,
  },
  in: {
    opacity: 1,
    scale: 1,
    y: 0,
  },
  out: {
    opacity: 0,
    scale: 0.9,
    y: -50,
  },
};

function AnimatedRoutes() {
  const location = useLocation();
  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        {/* Existing routes */}
        <Route
          path="/"
          element={
            <motion.div initial="initial" animate="in" exit="out" variants={pageVariants}>
              <Home />
            </motion.div>
          }
        />
        <Route
          path="/defi"
          element={
            <motion.div initial="initial" animate="in" exit="out" variants={pageVariants}>
              <DeFi />
            </motion.div>
          }
        />
        <Route
          path="/exchanges"
          element={
            <motion.div initial="initial" animate="in" exit="out" variants={pageVariants}>
              <Exchanges />
            </motion.div>
          }
        />
        {/* Add the new SeiGames route here */}
        <Route
          path="/seigames"
          element={
            <motion.div initial="initial" animate="in" exit="out" variants={pageVariants}>
              <SeiGames />
            </motion.div>
          }
        />
        {/* Other routes */}
        <Route
          path="/nfts"
          element={
            <motion.div initial="initial" animate="in" exit="out" variants={pageVariants}>
              <NFTs />
            </motion.div>
          }
        />
        <Route
          path="/wallets"
          element={
            <motion.div initial="initial" animate="in" exit="out" variants={pageVariants}>
              <Wallets />
            </motion.div>
          }
        />
        <Route
          path="/communities"
          element={
            <motion.div initial="initial" animate="in" exit="out" variants={pageVariants}>
              <Communities />
            </motion.div>
          }
        />
        <Route
          path="/spaces"
          element={
            <motion.div initial="initial" animate="in" exit="out" variants={pageVariants}>
              <Spaces />
            </motion.div>
          }
        />
        <Route
          path="/meme"
          element={
            <motion.div initial="initial" animate="in" exit="out" variants={pageVariants}>
              <Meme />
            </motion.div>
          }
        />
        <Route
          path="/seiguides"
          element={
            <motion.div initial="initial" animate="in" exit="out" variants={pageVariants}>
              <NewToSEI />
            </motion.div>
          }
        />
        {/* Other routes continue */}
        <Route
          path="/mediakits"
          element={
            <motion.div initial="initial" animate="in" exit="out" variants={pageVariants}>
              <MediaKits />
            </motion.div>
          }
        />
        <Route
          path="/devstuff"
          element={
            <motion.div initial="initial" animate="in" exit="out" variants={pageVariants}>
              <DevStuff />
            </motion.div>
          }
        />
        <Route
          path="/bridges"
          element={
            <motion.div initial="initial" animate="in" exit="out" variants={pageVariants}>
              <Bridges />
            </motion.div>
          }
        />
        <Route
          path="/explorers"
          element={
            <motion.div initial="initial" animate="in" exit="out" variants={pageVariants}>
              <Explorers />
            </motion.div>
          }
        />
        <Route
          path="/other-tools"
          element={
            <motion.div initial="initial" animate="in" exit="out" variants={pageVariants}>
              <OtherTools />
            </motion.div>
          }
        />
        <Route
          path="/analytics"
          element={
            <motion.div initial="initial" animate="in" exit="out" variants={pageVariants}>
              <Analytics />
            </motion.div>
          }
        />
      </Routes>
    </AnimatePresence>
  );
}

function App() {
  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <Navbar />
        </header>
        <div className="content">
          <AnimatedRoutes />
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
