import palletLogo from '../icons/Pallet.png';
import frensei from '../icons/Frensei.png';
import meLogo from '../icons/Magic Eden.png';
import ripOverlay from '../images/rip.png';

export const nftData = [
  {
    name: "Frensei Launchpad",
    logo: frensei,
    description: "Frensei is a platform by On-Chain Orbiters on the Sei Network, offering tools and services for creating and launching NFT projects.",
    features: [
      'Launchpad: A scalable, application-based NFT launchpad with features like dynamic mint allocations and an allowlist manager.',
      'Coins: Create ERC-20 or Sei Factory tokens using a simple no-code experience.',
      'Funding: Received direct funding from Sei Foundation.'
    ],
    website: "https://www.frensei.io/",
    twitter: "https://x.com/orbitersnft",
  },
  {
    name: "Magic Eden",
    logo: meLogo,
    description: "Magic Eden is a leading NFT marketplace that empowers digital creators and collectors by offering a platform for discovery, expression, and ownership across digital cultures.",
    features: [
      'Launchpad: An exclusive minting platform that selectively accepts only top-tier projects, offering development support and exposure for creators.',
    ],
    website: "https://magiceden.io/",
    twitter: "https://x.com/MagicEden",
  },
{
  name: "Pallet Exchange",
  logo: palletLogo,
  description: "Pallet Exchange was the leading NFT marketplace on SEI.",
  features: [
    'Airdropped 461,300 SEI to their top 500 users before shutting down.',
    '81,996 unique wallets bought or sold NFTs.',
	'41,211,611 SEI total volume.',
    '512 collections supported.',


  ],
  website: "https://pallet.exchange",
  twitter: "https://x.com/pallet_exchange",
  overlay: ripOverlay  // Adding the overlay image
}

];
