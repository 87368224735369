import React from 'react';
import { motion } from 'framer-motion';
import { FaTwitter, FaGlobe } from 'react-icons/fa';
import SEILogo from '../icons/SEI.png';
import { nftData } from '../data/nftData';

const cardHoverVariants = {
  hover: {
    scale: 1.05,
    transition: {
      duration: 0.3,
      ease: 'easeInOut',
    },
  },
};

const NFTCard = ({ logo, name, description, features, website, twitter, overlay }) => (
  <motion.div
    initial={{ opacity: 0, y: 50, scale: 0.9 }}
    animate={{ opacity: 1, y: 0, scale: 1 }}
    exit={{ opacity: 0, y: -50, scale: 0.9 }}
    whileHover="hover"
    variants={cardHoverVariants}
    className="relative bg-gray-900 p-4 md:p-6 rounded-lg shadow-lg flex flex-col items-center space-y-4 mb-6 w-full max-w-sm md:max-w-2xl mx-auto overflow-hidden"
  >
    {overlay && (
      <div className="absolute inset-0 flex items-center justify-center p-6 z-10">
        <img
          src={overlay}
          alt="Overlay"
          className="w-full h-full object-contain opacity-75"
        />
      </div>
    )}
    <div className="relative z-20 flex flex-col items-center w-full">
      <img src={logo} alt={`${name} Logo`} className="w-16 h-16 md:w-24 md:h-24 rounded-full" />
      <div className="text-left w-full mt-4">
        <h3 className="text-lg md:text-2xl font-bold mb-2">{name}</h3>
        <p className="text-sm md:text-gray-400 mb-2">{description}</p>
        <ul className="list-disc list-inside text-sm md:text-gray-400 mb-2 space-y-1 text-left">
          {features.map((feature, index) => (
            <li key={index} className="whitespace-normal">{feature}</li>
          ))}
        </ul>
        <div className="space-x-4 mt-4 flex items-center">
          {website && (
            <a href={website} target="_blank" rel="noopener noreferrer" aria-label="Website">
              <FaGlobe className="text-blue-500 hover:text-blue-700" size={20} />
            </a>
          )}
          {twitter && (
            <a href={twitter} target="_blank" rel="noopener noreferrer" aria-label="Twitter">
              <FaTwitter className="text-blue-500 hover:text-blue-700" size={20} />
            </a>
          )}
        </div>
      </div>
    </div>
  </motion.div>
);

const NFTs = () => {
  return (
    <div className="flex flex-col items-center justify-center text-white p-4 pb-16">
      <h1 className="text-2xl md:text-4xl font-bold text-center text-white mb-6 flex items-center justify-center">
        <img src={SEILogo} alt="SEI Logo" className="w-8 h-8 mr-2" />
        NFT Marketplaces And Launchpads
      </h1>
      
      <div className="hidden md:grid w-full max-w-5xl grid-cols-1 md:grid-cols-2 gap-6 mt-6">
        {nftData.map((project, index) => (
          <NFTCard key={index} {...project} />
        ))}
      </div>
      <div className="md:hidden w-full">
        {nftData.map((project, index) => (
          <NFTCard key={index} {...project} />
        ))}
      </div>
    </div>
  );
};

export default NFTs;
